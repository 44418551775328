@import '@core/scss/core.scss';
@import './assets/scss/styles';


// Color
.color-red {
  color: red;
}

.link-color {
  color: #7367f0 !important;
}

.hoverBanrdColor:hover {
  color: #7367f0 !important;
}

.bg-transparent {
  background: transparent;
}

.bg-white {
  background-color: #fff;
}

.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.fw-300 {
  font-weight: 300;
}

.fs-15 {
  font-size: 15px;
}
.fs-14{
  font-size: 14px !important;
}

.border-radiud-zero {
  border-radius: 0px !important;
}

.pl7 {
  padding-left: 7px;
}

.text-black {
  color: #000;
}

.borderBottm2px{
  border-bottom: 2px solid #f7f6f9;
}

// Logo
.collapsedLogo img {
  max-width: 30px;
}

.navbar-container.d-flex.content {
  border-bottom: 2px solid #f7f6f9;
}

.sectionBottomBorder {
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
}

.updatedStyle .ng-select.ng-select-single .ng-select-container {
  height: 30px;
}

.updatedStyle .ng-dropdown-panel-items.scroll-host {
  font-size: 10px;
}
.custom-switch.updatedStyle .custom-control-label::before {
  height: 15px;
  width: 37px;
  top: 5px
}

.custom-switch.updatedStyle .custom-control-label::after {
  height: 10px;
  width: 10px;
  top: 7.5px;
}

.avatar.rounded {
  height: fit-content;
}

h2.swal2-title {
  font-size: 20px !important;
}


.upDownIcon {
  font-size: 22px;
  border: 1px solid;
  height: max-content;
  width: max-content;
  border-radius: 50px;
  color: #7367f0;
  margin: 5px;
}

.deleteIcon {
  color: red;
  font-size: 14px;
  margin: 5px;
  border: 1px solid;
  border-radius: 50px;
  padding: 4px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}


.marginLeft5px {
  margin-left: 5px;
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #efefef;
  top: 0;
  z-index: 10000000000000000;
  left: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.accordion_bottom_padding0 .card-body {
  padding-bottom: 0px !important;
}


// Form design page
.stickyTopHeader{
  position: sticky;
  top: 64px;
  z-index: 10;
  padding: 7px 14px 5px 14px !important;
  border-bottom: 2px solid #f3f2f5;
  background-color: #fff;
}
.sidebarFields{
  position: sticky !important;
  top: 109px !important;
  z-index: 9 !important;
}
.leftPanelAccordian {
  position: sticky;
  top: 107px;
}
.blankTabShadow .accordion .card .card-header button {
  background: #f7f6f9 !important;
  border-radius: 0px !important;
}
.blankTabShadow .card {
  border-radius: 0px !important;
  box-shadow: none !important;
}

html .content.app-content:has(.csutomContentSpace) {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.minHeight100vh{
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: $gray-300;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #f2f1f4;
  border-radius: 20px;
}


// Drwaer
.isDrawerClose .collaspe-isDrawerClose{
  display: none;
  width: 0px;
  height: 0px;
}
 
.stickyIcon{
  display: flex;
  align-items: center;
  position: sticky;
  top: 55%;
  background: #fff;
  padding: 25px 5px;  
  border: 1px solid #f8f7fa;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); 
  z-index: 99;
}

.smoothTransition{
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.text-wrapper::after {
  content: ">";
  position: absolute;
  right: -20px;
  background: #fff;
  padding: 25px 5px;
  z-index: 999;
  top: 50%;
  cursor: pointer;
} 



#editSideBar .nav-pills .nav-link { 
  font-size: 12px !important;
  font-weight: 400 !important;
}
#editSideBar ul.nav-tabs.nav.horizonatalScroll {
  display: flex;
  flex-wrap: nowrap; 
  background: #fafafa;
    overflow-x: scroll;
    border-radius: 0;
    margin-bottom: 0px;
 
}

.bg-fafafa{
  background: #fafafa;
}
ul.nav-tabs.horizonatalScroll.nav {
  padding-left: 1rem;
  padding-right: 1rem;
}

.z-index9{
  z-index: 99;
}
.updatedStyle .ng-select-size-sm.ng-select.ng-select-single.ng-select-searchable.ng-select-clearable{
  z-index: 1 !important;
}


.drop-zone{
  border: 1px dashed #ccc;   
    background-color: #f8f7fa;
    border-color: #999;
    padding: 20px;
}

.placeholder-text::before {
  content: 'Drop Element Here';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ddd;
  z-index: -0;
}

.overflowXScroll{
  overflow-x: scroll;
  width: 100%;
}

.nav-tabs .nav-link:after {
  background: #7367f0 !important;
  height: 2px;
}

.lightBoxShadow{
  box-shadow: 0 1px 5px 0 rgba(34, 41, 47, 0.1) !important;
}
.card-headerBottomBorder .card-header{
  border-bottom: 1.5px solid #efefef;
}

.height100vh{
  height: 100vh;
}
.maxHeight100vhScroll{
  max-height: 100vh;
  overflow-y: scroll;
}
.sdebarBoxShadow{
  box-shadow: 0 0px 4px 0 rgba(34, 41, 47, 0.1);
}
.rightSidebar{
  position: sticky;
  top: 107px; 
}
.hide{
  display: none !important;
}
.mw-90{
  max-width: 90%;
}

.checkboxNormal{
  height: max-content;
    outline: none;
}

.stickyTopZero {
  position: sticky;
  top: 0px;
  z-index: 9;
  padding: 1rem;
  background: #fff;
}
.setHeigitForFields{
  height: 250px;
  overflow-y: scroll;
}

.ng-select {
  z-index: 9; 
}

.col-sm-6:has(.ng-select-size-sm.ng-select.ng-select-opened.ng-select-bottom), .col-sm-12:has(.ng-select-size-sm.ng-select.ng-select-opened.ng-select-bottom) {
  z-index: 99999;
}

.cardv2{
  border: 1px solid #f8f8f8;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 1px 5px 0 rgba(34, 41, 47, 0.1) !important;
}
.drag-and-drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
}

.drag-and-drop-area:hover {
  background-color: #f9f9f9;
}